<template>
  <modal :show.sync="show" :on-close="closeModal">
    <woot-modal-header :header-title="title" :header-content="message" />
    <form @submit.prevent="onConfirm">
      <date-time-picker
        :value="value"
        :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
        :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
        :class="{ error: $v.value.$error }"
        @change="onChange"
        @blur="$v.value.$touch"
      />
      <div class="button-wrapper">
        <woot-button color-scheme="primary" :is-disabled="$v.value.$invalid">
          {{ confirmText }}
        </woot-button>
        <woot-button class="clear" @click.prevent="closeModal">
          {{ rejectText }}
        </woot-button>
      </div>
    </form>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from '../Modal';
import DateTimePicker from '../ui/DateTimePicker.vue';
import { isFuture } from 'date-fns';

export default {
  components: {
    Modal,
    DateTimePicker,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    confirmPlaceHolderText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
    };
  },
  validations: {
    value: {
      required,
      isFuture(value) {
        return isFuture(value);
      },
    },
  },
  methods: {
    closeModal() {
      this.value = '';
      this.$emit('on-close');
    },
    onConfirm() {
      this.$emit('on-confirm', this.value);
    },
    onChange(value) {
      this.value = value;
    },
  },
};
</script>
