<template>
  <span>
    {{ formattedDistance }}
  </span>
</template>

<script>
import differenceInSeconds from 'date-fns/differenceInSeconds';
import parseISO from 'date-fns/parseISO';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      diffSeconds: 0,
      diffInterval: undefined,
    };
  },
  computed: {
    formattedDistance() {
      const hours = Math.floor(this.diffSeconds / 3600);
      const minutes = Math.floor((this.diffSeconds % 3600) / 60);
      const seconds = this.diffSeconds % 60;
      return hours + ':' + minutes + ':' + seconds;
    },
  },
  watch: {
    value() {
      this.resetDistance();
    },
  },
  created() {
    this.resetDistance();
    this.diffInterval = setInterval(() => {
      this.diffSeconds += 1;
    }, 1000);
  },
  beforeDestroy() {
    if (this.diffInterval) {
      clearInterval(this.diffInterval);
    }
  },
  methods: {
    resetDistance() {
      this.diffSeconds = differenceInSeconds(new Date(), parseISO(this.value));
    },
  },
};
</script>
